import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/auth';
import {
  TextField, Button, Grid, Box, Typography, Container, IconButton, InputAdornment,
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import logo from '../assets/TAT_light.png'; // Make sure to replace this with the path to your logo image

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    let isMounted = true;

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, { email, password });
      if (isMounted) {
        const { token } = response.data;
        localStorage.setItem('token', token);
        if (token) {
          login(token);
          navigate('/dashboard/home');
        } else {
          console.error("Login Failed");
        }
      }
    } catch (error) {
      if (isMounted) {
        console.error('Login error:', error);
        alert('Invalid email or password');
      }
    }

    return () => {
      isMounted = false;
    };
  };

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container maxWidth="md">
      <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        {/* Left section with welcome message */}
        <Grid item xs={12} md={6} style={styles.leftSection}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
            <Typography variant="h2" component="h1" color="white">
              Welcome
            </Typography>
            <img src={logo} alt="Logo" style={styles.logo} /> {/* Logo image */}
          </Box>
        </Grid>

        {/* Right section with login form */}
        <Grid item xs={12} md={6}>
          <Box p={4} boxShadow={3} borderRadius={3} bgcolor="white">
            <Typography variant="h4" component="h1" gutterBottom>
              Sign In
            </Typography>

            <Box display="flex" justifyContent="flex-end" mb={2}>
              <IconButton color="primary" component="a" href="#">
                <FacebookIcon />
              </IconButton>
              <IconButton color="primary" component="a" href="#">
                <TwitterIcon />
              </IconButton>
            </Box>

            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                margin="normal"
                label="Email"
                variant="outlined"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <TextField
                fullWidth
                margin="normal"
                label="Password"
                variant="outlined"
                type={showPassword ? 'text' : 'password'} // Toggle input type between text and password
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={togglePasswordVisibility}
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: '20px' }}>
                Sign In
              </Button>

              <Box display="flex" justifyContent="space-between" mt={2}>
                <Box>
                  <label className="checkbox-wrap checkbox-primary">
                    <input type="checkbox" defaultChecked />
                    <span>Remember Me</span>
                  </label>
                </Box>
                <Box>
                  <Button color="primary" href="#">
                    Forgot Password?
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const styles = {
  leftSection: {
    backgroundColor: '#007bff', // Similar background color
    backgroundSize: 'cover',
    color: 'white',
    padding: '50px',
  },
  logo: {
    width: '150px', // Adjust the size of the logo
    marginTop: '20px', // Add some spacing between the text and the logo
  },
};

export default Login;
