
import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { PieChart, BarChart } from '@mui/x-charts';
import axios from 'axios';

const Home = () => {
  const [entities, setEntities] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState('all'); // Default to 'all'
  const [chartData, setChartData] = useState({
    pieData: [], // Initialize as an empty array
    barData: []  // Initialize as an empty array
  });

  useEffect(() => {
    const fetchEntities = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/entities`);
        setEntities(response.data);
      } catch (error) {
        console.error('Error fetching entities:', error);
      }
    };

    fetchEntities();
  }, []);

  useEffect(() => {
    const fetchEntityData = async (entityId) => {
      try {
        const url = entityId === 'all'
          ? `${process.env.REACT_APP_BASE_URL}/auth/entities/data`  // Ensure this endpoint aggregates data for all entities
          : `${process.env.REACT_APP_BASE_URL}/auth/entities/${entityId}/data`;

        const response = await axios.get(url);
        const data = response.data;

        setChartData({
          pieData: data.pieData || [], // Ensure pieData is defined or default to an empty array
          barData: data.barData || [], // Ensure barData is defined or default to an empty array
        });

        // Debugging the data
        console.log('Pie Data:', data.pieData);
        console.log('Bar Data:', data.barData);
      } catch (error) {
        console.error('Error fetching entity data:', error);
        setChartData({
          pieData: [
            { id: 0, value: 0, label: 'Verified' },
            { id: 1, value: 0, label: 'Not Verified' },
            { id: 2, value: 0, label: 'N/A' },
          ],
          barData: [
            { condition: 'Quantity', value: 0 },
            { condition: 'Good', value: 0 },
            { condition: 'Fair', value: 0 },
            { condition: 'Bad', value: 0 },
          ]
        });
      }
    };

    fetchEntityData(selectedEntity);
  }, [selectedEntity]);

  const handleEntityChange = (event) => {
    setSelectedEntity(event.target.value);
  };

  return (
    <Container>
      <Typography variant="h4" sx={{ mt: 2 }}>Generate Reports</Typography>

      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="entity-select-label">Select Entity</InputLabel>
        <Select
          labelId="entity-select-label"
          id="entity-select"
          value={selectedEntity}
          label="Select Entity"
          onChange={handleEntityChange}
        >
          <MenuItem value="all">All</MenuItem> {/* "All" option */}
          {entities.map((entity) => (
            <MenuItem key={entity.id} value={entity.id}>
              {entity.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Container sx={{ display: 'block' }}>
          <Typography variant="h5" sx={{ mt: 2 }}>Fixed Asset Verification</Typography>
          <PieChart
            sx={{ mt: 4, width: '100%', height: 300 }}
            series={[
              {
                data: chartData.pieData,
                innerRadius: 10,
                outerRadius: 100,
                paddingAngle: 2,
                cornerRadius: 5,
                startAngle: -180,
                endAngle: 180,
                arcLabel: (item) => `${item.value}`,
                arcLabelMinAngle: 5,
              },
            ]}
            width={400}
            height={300}
          />
        </Container>

        <Container sx={{ display: 'block' }}>
          <Typography variant="h5" sx={{ mt: 2 }}>Data Summary</Typography>
          <BarChart
            dataset={chartData.barData}
            yAxis={[{ scaleType: 'band', dataKey: 'condition' }]}
            series={[{ dataKey: 'value', label: 'Data Summary' }]}
            layout="horizontal"
            width={500}
            height={300}
          />
        </Container>
      </Box>
    </Container>
  );
};

export default Home;
