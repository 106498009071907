
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Typography, TextField, Button, Grid } from '@mui/material';

const EditAssetType = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState('');

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/assetTypes/${id}`, { signal })
      .then(response => {
        setName(response.data.name);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error fetching asset type:', error);
        }
      });

    return () => {
      controller.abort(); // Cleanup: Cancel the request if the component unmounts
    };
  }, [id]);

  const handleUpdateAssetType = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/updateAssetType/${id}`, {
        name
      });
      alert('Asset Type Updated!');
      navigate('/dashboard/assetTypes');
    } catch (error) {
      alert('Asset Type Not Updated!');
      console.error('Error updating asset type:', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 2 }}>
        Edit Asset Type
      </Typography>
      <form onSubmit={handleUpdateAssetType}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Asset Type Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Update Asset Type
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default EditAssetType;
