
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MenuItem, FormControl, InputLabel, TextField, Button, Container, Typography, Grid, Select } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [firstname, setFirstname] = useState('');
  const [middlename, setMiddlename] = useState('');
  const [lastname, setLastname] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState('');
  const [entity, setEntity] = useState('');
  const [branch, setBranch] = useState('');

  const [roles, setRoles] = useState([]);
  const [entities, setEntities] = useState([]);
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    // Fetch roles
    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/roles`, { signal })
      .then(response => setRoles(response.data))
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Fetch roles request canceled:', error.message);
        } else {
          console.error('Error fetching roles:', error);
        }
      });

    // Fetch entities
    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/entities`, { signal })
      .then(response => setEntities(response.data))
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Fetch entities request canceled:', error.message);
        } else {
          console.error('Error fetching entities:', error);
        }
      });

    // Fetch branches
    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/branches`, { signal })
      .then(response => setBranches(response.data))
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Fetch branches request canceled:', error.message);
        } else {
          console.error('Error fetching branches:', error);
        }
      });

    // Fetch user data
    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/users/${id}`, { signal })
      .then(response => {
        const user = response.data;
        setFirstname(user.firstname || '');
        setMiddlename(user.middlename || '');
        setLastname(user.lastname || '');
        setUsername(user.username || '');
        setEmail(user.email || '');
        setRole(user.role || '');
        setEntity(user.entity || '');
        setBranch(user.branch || '');
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Fetch user data request canceled:', error.message);
        } else {
          console.error('Error fetching user data:', error);
        }
      });

    return () => {
      controller.abort(); // Cleanup: Cancel all requests if the component unmounts
    };
  }, [id]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/editUsers/${id}`, {
        firstname, middlename, lastname, username, email, password, role, entity, branch
      });
      alert('Update successful');
      navigate('/dashboard/users');
    } catch (error) {
      alert('Update failed');
      console.error('Error updating user:', error);
    }
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" component="h1" sx={{ mt: 2 }} gutterBottom>
        Edit User
      </Typography>
      <form onSubmit={handleUpdate}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="First Name"
              variant="outlined"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Middle Name"
              variant="outlined"
              value={middlename}
              onChange={(e) => setMiddlename(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Last Name"
              variant="outlined"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Username"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Confirm Password"
              variant="outlined"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Role</InputLabel>
              <Select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                label="Role"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.name}>{role.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Entity</InputLabel>
              <Select
                value={entity}
                onChange={(e) => setEntity(e.target.value)}
                label="Entity"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {entities.map((entity) => (
                  <MenuItem key={entity.id} value={entity.name}>{entity.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Branch</InputLabel>
              <Select
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
                label="Branch"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.name}>{branch.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={{ mt: 3, mb: 4 }}>
            <Button sx={{ mb: 4 }} type="submit" variant="contained" color="primary" fullWidth>
              Update
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default EditUser;
