
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/auth';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import User from './components/User';
import EditUser from './components/EditUser';
import Home from './components/Home';
import Asset from './components/Asset';
import AssetList from './components/AssetList';
import EditAsset from './components/EditAsset';
import VerifyAsset from './components/VerifyAsset';
import AddAssetType from './components/AddAssetType';
import AssetTypeList from './components/AssetTypeList';
import EditAssetType from './components/EditAssetType';
import AddEntity from './components/AddEntity';
import EntityList from './components/EntityList';
import EditEntity from './components/EditEntity';
import AddBranch from './components/AddBranch';
import BranchList from './components/BranchList';
import EditBranch from './components/EditBranch';
import AddVendor from './components/AddVendor';
import VendorList from './components/VendorList';
import EditVendor from './components/EditVendor';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Login />} />
          {/*<Route path="/register" element={<Register />} />*/}

          {/* Protected Routes */}
          <Route path="/dashboard/*" element={<ProtectedRoute><DashboardLayout /></ProtectedRoute>} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

// Protect routes for authenticated users only
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/" />; // Redirect to login if not authenticated
  }

  return children; // Render the protected content if authenticated
};

// Layout for authenticated sections with a Sidebar and Topbar
const DashboardLayout = () => (
  <div style={{ display: 'flex' }}>
    <Sidebar />
    <div style={{ flex: 1 }}>
      <Topbar />
      <Routes>
        {/* Nested Routes for the dashboard */}
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="home" element={<Home />} />

        {/* User management routes */}
        <Route path="register" element={<Register />} />
        <Route path="users" element={<User />} />
        <Route path="editUsers/:id" element={<EditUser />} />

        {/* Asset management routes */}
        <Route path="addAsset" element={<Asset />} />
        <Route path="assets" element={<AssetList />} />
        <Route path="editAssets/:id" element={<EditAsset />} />
        <Route path="verifyAssets/:id" element={<VerifyAsset />} />

        {/* Asset type management routes */}
        <Route path="addAssetType" element={<AddAssetType />} />
        <Route path="assetTypes" element={<AssetTypeList />} />
        <Route path="editAssetType/:id" element={<EditAssetType />} />

        {/* Entity management routes */}
        <Route path="addEntity" element={<AddEntity />} />
        <Route path="entities" element={<EntityList />} />
        <Route path="editEntity/:id" element={<EditEntity />} />

        {/* Branch management routes */}
        <Route path="addBranch" element={<AddBranch />} />
        <Route path="branches" element={<BranchList />} />
        <Route path="editBranch/:id" element={<EditBranch />} />

        {/* Vendor management routes */}
        <Route path="addVendor" element={<AddVendor />} />
        <Route path="vendors" element={<VendorList />} />
        <Route path="editVendor/:id" element={<EditVendor />} />

        {/* Add other routes as needed */}
      </Routes>
    </div>
  </div>
);

export default App;
