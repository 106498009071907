
import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const phoneRegex = /^[0-9]{10}$/;

const AddVendor = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [contractTerms, setContractTerms] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    if (!phoneRegex.test(phone)) {
      newErrors.phone = 'Phone number must be 10 digits long.';
    }
    
    // Additional validation rules can be added here
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddVendor = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      let isMounted = true; // Flag to track if the component is mounted

      try {
        await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/addVendor`, { name, phone, address, contractTerms });
        if (isMounted) {
          alert('Vendor Added!');
          navigate('/dashboard/vendors');
        }
      } catch (error) {
        if (isMounted) {
          alert('Vendor Not Added!');
          console.error('Error adding vendor:', error);
        }
      }

      return () => {
        isMounted = false; // Cleanup function to prevent memory leaks
      };
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" sx={{ mt: 2 }} gutterBottom>
        Add Vendor
      </Typography>
      <form onSubmit={handleAddVendor}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Vendor Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Phone"
              variant="outlined"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              error={!!errors.phone}
              helperText={errors.phone}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Address"
              variant="outlined"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Contract Terms"
              variant="outlined"
              value={contractTerms}
              onChange={(e) => setContractTerms(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Add Vendor
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AddVendor;
