import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Button, Container, Box, Modal, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, InputAdornment
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';

// Styles for the modal box
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AssetList = () => {
  const [assets, setAssets] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false); // State for update modal
  const navigate = useNavigate();

  useEffect(() => {
    const source = axios.CancelToken.source(); // Create a source object for cancellation

    // Fetch assets from the API
    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/assets`, { cancelToken: source.token })
      .then(response => {
        console.log('Fetched Assets:', response.data);
        setAssets(response.data);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          console.error('Error fetching assets:', error);
        }
      });

    return () => {
      source.cancel('Component unmounted. Request canceled.');
    };
  }, []);

  const handleAsset = () => {
    navigate('/dashboard/addAsset');
  };

  const handleEdit = (assetId) => {
    navigate(`/dashboard/editAssets/${assetId}`);
  };

  const handleDelete = async (assetId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this asset?');
    if (!confirmDelete) return;

    try {
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/auth/assets/${assetId}`);
      setAssets(prevAssets => prevAssets.filter(asset => asset.id !== assetId));
    } catch (error) {
      console.error('Error deleting asset:', error);
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  // Open the modal
  const handleOpen = () => setOpen(true);

  // Close the modal
  const handleClose = () => setOpen(false);

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file first');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/upload-csv`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('CSV file uploaded successfully!');
      handleClose(); // Close modal on success
    } catch (error) {
      alert('Error uploading CSV file');
    }
  };

  // New function to handle updating assets from CSV
  const handleUpdateOpen = () => setUpdateOpen(true);
  const handleUpdateClose = () => setUpdateOpen(false);

  const handleUpdateUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file first');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/update-csv`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Assets updated successfully!');
      handleUpdateClose(); // Close modal on success
    } catch (error) {
      alert('Error updating assets from CSV');
    }
  };

  const handleVerify = (assetId) => {
    navigate(`/dashboard/verifyAssets/${assetId}`);
  };

  const filteredAssets = assets.filter((asset) =>
    asset.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
    asset.serialNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (asset.Vendor && asset.Vendor.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (asset.Entity && asset.Entity.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (asset.Branch && asset.Branch.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (asset.AssetType && asset.AssetType.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
     asset.condition.toLowerCase().includes(searchTerm.toLowerCase()) ||
     asset.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const headers = [
    { label: 'Code', key: 'code' },
    { label: 'Serial Number', key: 'serialNumber' },
    { label: 'Vendor', key: 'Vendor.name' },
    { label: 'Purchase Cost ($)', key: 'purchaseCost' },
    { label: 'Old Location', key: 'oldLocation' },
    { label: 'New Location', key: 'newLocation' },
    { label: 'Asset Type', key: 'AssetType.name' },
    { label: 'Purchase Date', key: 'purchaseDate' },
    { label: 'Useful Life (yrs)', key: 'usefulLife' },
    { label: 'Custodian', key: 'custodian' },
    { label: 'Condition', key: 'condition' },
    { label: 'Entity', key: 'Entity.name' },
    { label: 'Branch', key: 'Branch.name' },
    { label: 'Verification Status', key: 'verificationStatus' },
  ];

  // CSV template content for the upload
  const csvTemplate = [
    ["code", "serialNumber", "vendorId", "purchaseCost", "oldLocation", "newLocation", "assetTypeId", "purchaseDate", "usefulLife", "custodian", "condition", "description", "entityId", "branchId"],
  ];

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        Assets
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <TextField
          label="Search Assets"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAsset}
            style={{ marginRight: '16px' }}
          >
            Add Asset
          </Button>
          <CSVLink data={filteredAssets} headers={headers} filename="assets.csv" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginRight: '16px' }}
              startIcon={<DownloadIcon />}
            >
              Download CSV
            </Button>
          </CSVLink>
          {/* Button to open modal for uploading new assets */}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpen}
            startIcon={<UploadIcon />}
          >
            Upload CSV
          </Button>

          {/* New Button to open modal for updating assets */}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleUpdateOpen}
            startIcon={<UploadIcon />}
            style={{ marginLeft: '16px' }}
          >
            Update Assets from CSV
          </Button>

          {/* Modal for uploading new assets */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-title" variant="h6" component="h2">
                Upload CSV File
              </Typography>
              <TextField
                fullWidth
                type="file"
                onChange={handleFileChange}
                margin="normal"
                variant="outlined"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpload}
                startIcon={<UploadIcon />}
                fullWidth
              >
                Upload CSV
              </Button>
              <CSVLink data={csvTemplate} headers={headers} filename="asset-template.csv" style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '16px' }}
                  fullWidth
                >
                  Download CSV Template
                </Button>
              </CSVLink>
            </Box>
          </Modal>

          {/* Modal for updating assets */}
          <Modal
            open={updateOpen}
            onClose={handleUpdateClose}
            aria-labelledby="update-modal-title"
            aria-describedby="update-modal-description"
          >
            <Box sx={style}>
              <Typography id="update-modal-title" variant="h6" component="h2">
                Update Assets from CSV
              </Typography>
              <TextField
                fullWidth
                type="file"
                onChange={handleFileChange}
                margin="normal"
                variant="outlined"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateUpload}
                startIcon={<UploadIcon />}
                fullWidth
              >
                Update Assets from CSV
              </Button>
            </Box>
          </Modal>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Serial Number</TableCell>
              <TableCell>Vendor</TableCell>
              <TableCell>Purchase Cost ($)</TableCell>
              <TableCell>Old Location</TableCell>
              <TableCell>New Location</TableCell>
              <TableCell>Asset Type</TableCell>
              <TableCell>Purchase Date</TableCell>
              <TableCell>Useful Life (yrs)</TableCell>
              <TableCell>Custodian</TableCell>
              <TableCell>Condition</TableCell>
              <TableCell>Entity</TableCell>
              <TableCell>Branch</TableCell>
              <TableCell>Verification Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAssets.map((asset) => (
              <TableRow key={asset.id}>
                <TableCell>{asset.code}</TableCell>
                <TableCell>{asset.serialNumber}</TableCell>
                <TableCell>{asset.Vendor?.name}</TableCell>
                <TableCell>{asset.purchaseCost}</TableCell>
                <TableCell>{asset.oldLocation}</TableCell>
                <TableCell>{asset.newLocation}</TableCell>
                <TableCell>{asset.AssetType?.name}</TableCell>
                <TableCell>{dayjs(asset.purchaseDate).format('MM/DD/YYYY')}</TableCell>
                <TableCell>{asset.usefulLife}</TableCell>
                <TableCell>{asset.custodian}</TableCell>
                <TableCell>{asset.condition}</TableCell>
                <TableCell>{asset.Entity?.name}</TableCell>
                <TableCell>{asset.Branch?.name}</TableCell>
                <TableCell>{asset.verificationStatus}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEdit(asset.id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDelete(asset.id)}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton style={{ color: '#4CAF50' }} onClick={() => handleVerify(asset.id)}>
                    <CheckCircleIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AssetList;
