
import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AddAssetType = () => {
  const [name, setName] = useState('');
  const navigate = useNavigate();

  const handleAssetType = async (e) => {
    e.preventDefault();
    let isMounted = true; // Track if the component is mounted

    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/addAssetType`, { name });
      if (isMounted) {
        alert('Asset Type Added!');
        navigate('/dashboard/assetTypes');
      }
    } catch (error) {
      if (isMounted) {
        alert('Asset Type Not Added!');
      }
    }

    return () => {
      isMounted = false; // Cleanup function to set isMounted to false when the component unmounts
    };
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" sx={{ mt: 3, mb: 3 }} gutterBottom>
        Add Asset Type
      </Typography>
      <form onSubmit={handleAssetType}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Asset Type Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Add Asset Type
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AddAssetType;
