
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Avatar, IconButton, Menu, MenuItem, Box } from '@mui/material';
import axios from 'axios';
import { useAuth } from '../context/auth';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Fixed import for jwtDecode
import MenuIcon from '@mui/icons-material/Menu'; // Menu icon for mobile view
import Sidebar from './Sidebar'; // Sidebar component

const Topbar = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [entity, setEntity] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false); // Sidebar visibility for mobile

  useEffect(() => {
    let isMounted = true;

    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const decodedToken = jwtDecode(token);
          if (isMounted) {
            setUsername(decodedToken.username);
            setRole(decodedToken.role); // Set the user role

            // Fetch the user entity from the backend
            const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/user-info`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            if (isMounted) {
              setEntity(data.entity);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();

    return () => {
      isMounted = false; // Clean up on component unmount
    };
  }, [isAuthenticated]);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar); // Toggle sidebar visibility on mobile
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {/* Show MenuIcon for mobile view */}
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleSidebar}>
            <MenuIcon />
          </IconButton>

          {/* Entity and Role display */}
          <Box sx={{ flexGrow: 1, padding: 3 }}>
            <Typography variant="h6">{entity}</Typography>
            <Typography variant="body2">{role}</Typography>
          </Box>

          {/* User Avatar and Menu */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ marginRight: 1 }}>
              {username}
            </Typography>
            <IconButton onClick={handleMenuOpen}>
              <Avatar alt={username} src="/path/to/avatar.jpg" />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Sidebar Component - pass role and sidebar state */}
      <Sidebar userRole={role} showSidebar={showSidebar} toggleSidebar={toggleSidebar} />
    </>
  );
};

export default Topbar;
