
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Container, Typography, Grid } from '@mui/material';

const phoneRegex = /^[0-9]{10}$/; // Adjust this regex based on your phone number format

const EditVendor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    address: '',
    contractTerms: ''
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/vendors/${id}`, { signal })
      .then(response => {
        const vendor = response.data;
        setFormData({
          name: vendor.name,
          phone: vendor.phone,
          address: vendor.address,
          contractTerms: vendor.contractTerms
        });
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Fetch vendor request canceled:', error.message);
        } else {
          console.error('Error fetching vendor:', error);
        }
      });

    return () => {
      controller.abort(); // Cleanup: Cancel the request if the component unmounts
    };
  }, [id]);

  const validateForm = () => {
    const newErrors = {};
    if (!phoneRegex.test(formData.phone)) {
      newErrors.phone = 'Phone number must be 10 digits long.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleUpdateVendor = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/updateVendor/${id}`, formData);
        alert('Vendor Updated!');
        navigate('/dashboard/vendors');
      } catch (error) {
        alert('Vendor Not Updated!');
        console.error('Error updating vendor:', error);
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" sx={{ mt: 2 }} gutterBottom>
        Edit Vendor
      </Typography>
      <form onSubmit={handleUpdateVendor}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Vendor Name"
              variant="outlined"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Phone"
              variant="outlined"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
              error={!!errors.phone}
              helperText={errors.phone}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Address"
              variant="outlined"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Contract Terms"
              variant="outlined"
              name="contractTerms"
              value={formData.contractTerms}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Update Vendor
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default EditVendor;
