
import React, { useState } from 'react';
import { List, ListItem, ListItemText, Drawer, Box, Collapse, IconButton, useMediaQuery } from '@mui/material';
import { ExpandLess, ExpandMore, ChevronLeft, ChevronRight, Dashboard, People, GroupWork, LocalOffer, AddCircle } from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

const Sidebar = ({ userRole, showSidebar, toggleSidebar }) => {
  const [open, setOpen] = useState({});
  const [collapsed, setCollapsed] = useState(false);

  // Media query to detect mobile view
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Function to toggle individual collapsible sections
  const handleToggle = (key) => {
    setOpen(prevOpen => ({
      ...prevOpen,
      [key]: !prevOpen[key]
    }));
  };

  // Function to collapse/expand the sidebar
  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const drawerWidth = collapsed ? (isMobile ? 60 : 240) : (isMobile ? 240 : 240);

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={showSidebar}
      onClose={toggleSidebar}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          padding: '16px',
          transition: 'width 0.3s',
          overflow: 'hidden',
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '0 8px' }}>
        <IconButton onClick={handleCollapse}>
          {collapsed ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </Box>

      <Box sx={{ overflow: 'auto' }}>
        <List>
          {/* Dashboard */}
          <ListItem button onClick={() => handleToggle('dashboard')}>
            <Dashboard sx={{ mr: 2, fontSize: 24 }} />
            <ListItemText primary="Dashboard" />
            {open['dashboard'] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open['dashboard']} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/dashboard/home" sx={{ pl: 4 }}>
                <Dashboard sx={{ mr: 2, fontSize: 24 }} />
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem button component={Link} to="/dashboard/settings" sx={{ pl: 4 }}>
                <Dashboard sx={{ mr: 2, fontSize: 24 }} />
                <ListItemText primary="Settings" />
              </ListItem>
            </List>
          </Collapse>

          {/* User Management */}
          {userRole !== 'Approver' && (
          <>
          <ListItem button onClick={() => handleToggle('userManagement')} sx={{ justifyContent: collapsed ? 'center' : 'flex-start' }}>
            <People sx={{ mr: collapsed ? 0 : 2, fontSize: 24 }} />
            {!collapsed && <ListItemText primary="User Management" />}
            {!collapsed && (open['userManagement'] ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          <Collapse in={open['userManagement']} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/dashboard/register" sx={{ pl: collapsed ? 0 : 4, justifyContent: collapsed ? 'center' : 'flex-start' }}>
                <AddCircle sx={{ mr: collapsed ? 0 : 2, fontSize: 24 }} />
                {!collapsed && <ListItemText primary="Register" />}
              </ListItem>
              <ListItem button component={Link} to="/dashboard/users" sx={{ pl: collapsed ? 0 : 4, justifyContent: collapsed ? 'center' : 'flex-start' }}>
                <People sx={{ mr: collapsed ? 0 : 2, fontSize: 24 }} />
                {!collapsed && <ListItemText primary="Users Accounts" />}
              </ListItem>
            </List>
          </Collapse>
          </>
          )}

          {/* Asset Management */}
          <ListItem button onClick={() => handleToggle('assetManagement')} sx={{ justifyContent: collapsed ? 'center' : 'flex-start' }}>
            <AssignmentReturnedIcon sx={{ mr: collapsed ? 0 : 2, fontSize: 24 }} />
            {!collapsed && <ListItemText primary="Asset Management" />}
            {!collapsed && (open['assetManagement'] ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          <Collapse in={open['assetManagement']} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/dashboard/assets" sx={{ pl: collapsed ? 0 : 4, justifyContent: collapsed ? 'center' : 'flex-start' }}>
                <AssignmentReturnedIcon sx={{ mr: collapsed ? 0 : 2, fontSize: 24 }} />
                {!collapsed && <ListItemText primary="Asset" />}
              </ListItem>
              {userRole !== 'Approver' && (
                <>
              <ListItem button component={Link} to="/dashboard/addAsset" sx={{ pl: collapsed ? 0 : 4, justifyContent: collapsed ? 'center' : 'flex-start' }}>
                <AddCircle sx={{ mr: collapsed ? 0 : 2, fontSize: 24 }} />
                {!collapsed && <ListItemText primary="Add Asset" />}
              </ListItem>
              <ListItem button component={Link} to="/dashboard/assetTypes" sx={{ pl: collapsed ? 0 : 4, justifyContent: collapsed ? 'center' : 'flex-start' }}>
                <LocalOffer sx={{ mr: collapsed ? 0 : 2, fontSize: 24 }} />
                {!collapsed && <ListItemText primary="Asset Types" />}
              </ListItem>
              <ListItem button component={Link} to="/dashboard/addAssetType" sx={{ pl: collapsed ? 0 : 4, justifyContent: collapsed ? 'center' : 'flex-start' }}>
                <AddCircle sx={{ mr: collapsed ? 0 : 2, fontSize: 24 }} />
                {!collapsed && <ListItemText primary="Add Asset Type" />}
              </ListItem>
                </>
                 )}
            </List>
          </Collapse>

          {/* Entity Management */}
          {userRole !== 'Approver' && (
            <>
          <ListItem button onClick={() => handleToggle('entityManagement')} sx={{ justifyContent: collapsed ? 'center' : 'flex-start' }}>
            <AssuredWorkloadIcon sx={{ mr: collapsed ? 0 : 2, fontSize: 24 }} />
            {!collapsed && <ListItemText primary="Entity Management" />}
            {!collapsed && (open['entityManagement'] ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          <Collapse in={open['entityManagement']} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/dashboard/entities" sx={{ pl: collapsed ? 0 : 4, justifyContent: collapsed ? 'center' : 'flex-start' }}>
                <AssuredWorkloadIcon sx={{ mr: collapsed ? 0 : 2, fontSize: 24 }} />
                {!collapsed && <ListItemText primary="Entities" />}
              </ListItem>
              <ListItem button component={Link} to="/dashboard/addEntity" sx={{ pl: collapsed ? 0 : 4, justifyContent: collapsed ? 'center' : 'flex-start' }}>
                <AddCircle sx={{ mr: collapsed ? 0 : 2, fontSize: 24 }} />
                {!collapsed && <ListItemText primary="Add Entity" />}
              </ListItem>

            </List>
          </Collapse>
          </>
          )}

                    {/*Branch Management */}
          {userRole !== 'Approver' && (
            <>
          <ListItem button onClick={() => handleToggle('branchManagement')} sx={{ justifyContent: collapsed ? 'center' : 'flex-start' }}>
            <OtherHousesIcon sx={{ mr: collapsed ? 0 : 2, fontSize: 24 }} />
            {!collapsed && <ListItemText primary="Branch Management" />}
            {!collapsed && (open['branchManagement'] ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          <Collapse in={open['branchManagement']} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>

              <ListItem button component={Link} to="/dashboard/branches" sx={{ pl: collapsed ? 0 : 4, justifyContent: collapsed ? 'center' : 'flex-start' }}>
                <OtherHousesIcon sx={{ mr: collapsed ? 0 : 2, fontSize: 24 }} />
                {!collapsed && <ListItemText primary="Branches" />}
              </ListItem>
              <ListItem button component={Link} to="/dashboard/addBranch" sx={{ pl: collapsed ? 0 : 4, justifyContent: collapsed ? 'center' : 'flex-start' }}>
                <AddCircle sx={{ mr: collapsed ? 0 : 2, fontSize: 24 }} />
                {!collapsed && <ListItemText primary="Add Branch" />}
              </ListItem>



            </List>
          </Collapse>
          </>
          )}

                    {/* Vendor Management */}
          {userRole !== 'Approver' && (
            <>
          <ListItem button onClick={() => handleToggle('vendorManagement')} sx={{ justifyContent: collapsed ? 'center' : 'flex-start' }}>
            <AccountBalanceIcon sx={{ mr: collapsed ? 0 : 2, fontSize: 24 }} />
            {!collapsed && <ListItemText primary="Vendor Management" />}
            {!collapsed && (open['vendorManagement'] ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          <Collapse in={open['vendorManagement']} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/dashboard/vendors" sx={{ pl: collapsed ? 0 : 4, justifyContent: collapsed ? 'center' : 'flex-start' }}>
                <AccountBalanceIcon sx={{ mr: collapsed ? 0 : 2, fontSize: 24 }} />
                {!collapsed && <ListItemText primary="Vendors" />}
              </ListItem>
              <ListItem button component={Link} to="/dashboard/addVendor" sx={{ pl: collapsed ? 0 : 4, justifyContent: collapsed ? 'center' : 'flex-start' }}>
                <AddCircle sx={{ mr: collapsed ? 0 : 2, fontSize: 24 }} />
                {!collapsed && <ListItemText primary="Add Vendor" />}
              </ListItem>



            </List>
          </Collapse>
          </>
          )}





          {/* Additional sections can be added here similarly */}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
