import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const BranchList = () => {
  const [branches, setBranches] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/branches`, { signal })
      .then(response => {
        setBranches(response.data);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error fetching branches:', error);
        }
      });

    return () => {
      controller.abort(); // Cancel the request if the component unmounts
    };
  }, []);

  const handleAddBranch = () => {
    navigate('/dashboard/addBranch');
  };

  const handleEditBranch = (branchId) => {
    navigate(`/dashboard/editBranch/${branchId}`);
  };

  const handleDeleteBranch = (branchId) => {
    console.log('Delete Branch', branchId);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" sx={{ mt: 3, mb: 3 }} gutterBottom>
        Branches
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddBranch}
        style={{ marginBottom: '16px', float: 'right' }}
      >
        Add Branch
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Entity ID</TableCell>
              <TableCell>Entity</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {branches.map((branch) => (
              <TableRow key={branch.id}>
                <TableCell>{branch.name}</TableCell>
                <TableCell>{branch.address}</TableCell>
                <TableCell>{branch.entityId}</TableCell>
                <TableCell>{branch.Entity?.name || 'N/A'}</TableCell> {/* Safely access entity name */}
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEditBranch(branch.id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDeleteBranch(branch.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default BranchList;

