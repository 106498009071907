
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import {
  MenuItem, FormControl, InputLabel, TextField, Button, Container,
  Typography, Grid, Select, Card, CardContent, Box
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const VerifyAsset = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [asset, setAsset] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [assetTypes, setAssetTypes] = useState([]);
  const [entities, setEntities] = useState([]);
  const [branches, setBranches] = useState([]);

  // States for form fields
  const [code, setCode] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [vendor, setVendor] = useState('');
  const [purchaseCost, setPurchaseCost] = useState('');
  const [oldLocation, setOldLocation] = useState('');
  const [newLocation, setNewLocation] = useState('');
  const [assetType, setAssetType] = useState('');
  const [purchaseDate, setPurchaseDate] = useState(dayjs());
  const [usefulLife, setUsefulLife] = useState('');
  const [custodian, setCustodian] = useState('');
  const [condition, setCondition] = useState('');
  const [description, setDescription] = useState('');
  const [entity, setEntity] = useState('');
  const [branch, setBranch] = useState('');
  const [verifiedBy, setVerifiedBy] = useState('');
  const [verificationDate, setVerificationDate] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState('N/A');

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        // Fetch asset details
        const assetResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/assets/${id}`);
        if (isMounted) {
          const asset = assetResponse.data;
          setAsset(asset);
          setCode(asset.code);
          setSerialNumber(asset.serialNumber);
          setVendor(asset.vendorId);
          setPurchaseCost(asset.purchaseCost);
          setOldLocation(asset.oldLocation);
          setNewLocation(asset.newLocation);
          setAssetType(asset.assetTypeId);
          setPurchaseDate(dayjs(asset.purchaseDate));
          setUsefulLife(asset.usefulLife);
          setCustodian(asset.custodian);
          setCondition(asset.condition);
          setDescription(asset.description);
          setEntity(asset.entityId);
          setBranch(asset.branchId);

          // Check verification status
          if (asset.verifiedBy) {
            setVerifiedBy(asset.verifiedBy);
            setVerificationDate(dayjs(asset.verificationDate));
            setIsVerified(asset.verificationStatus === 'Verified');
            setVerificationStatus(asset.verificationStatus || 'N/A');
          } else {
            setVerificationStatus('N/A');
          }
        }

        // Fetch vendors, asset types, entities, and branches
        const [vendorsResponse, assetTypesResponse, entitiesResponse, branchesResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BASE_URL}/auth/vendors`),
          axios.get(`${process.env.REACT_APP_BASE_URL}/auth/assetTypes`),
          axios.get(`${process.env.REACT_APP_BASE_URL}/auth/entities`),
          axios.get(`${process.env.REACT_APP_BASE_URL}/auth/branches`)
        ]);

        if (isMounted) {
          setVendors(vendorsResponse.data);
          setAssetTypes(assetTypesResponse.data);
          setEntities(entitiesResponse.data);
          setBranches(branchesResponse.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [id]);

  const handleEdit = (assetId) => {
    navigate(`/dashboard/editAssets/${assetId}`);
  };

  const handleUpdateAsset = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/updateAsset/${id}`, {
        code, serialNumber, vendor, purchaseCost, oldLocation, newLocation, assetType, purchaseDate, usefulLife, custodian, condition, description, entity, branch
      });
      alert('Asset Updated!');
      navigate('/dashboard/assets');
    } catch (error) {
      alert('Asset Not Updated!');
    }
  };

  const handleVerifyAsset = async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const decodedToken = jwtDecode(token);
        const verifier = decodedToken.username; // Assuming username is in token

        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/verifyAsset/${id}`, {
          verifiedBy: verifier,
          verificationDate: new Date(),
          verificationStatus: 'Verified',
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setVerifiedBy(response.data.verifiedBy);
        setVerificationDate(dayjs(response.data.verificationDate));
        setVerificationStatus('Verified');
        setIsVerified(true);
        alert('Asset Verified!');
      }
    } catch (error) {
      console.error('Error verifying asset:', error);
      alert('Asset Verification Failed!');
    }
  };

  const handleNotVerifyAsset = async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const decodedToken = jwtDecode(token);
        const verifier = decodedToken.username; // Assuming username is in token

        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/verifyAsset/${id}`, {
          verifiedBy: verifier,
          verificationDate: new Date(),
          verificationStatus: 'Not Verified',
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setVerifiedBy(response.data.verifiedBy);
        setVerificationDate(dayjs(response.data.verificationDate));
        setVerificationStatus('Not Verified');
        setIsVerified(false);
        alert('Asset Marked as Not Verified!');
      }
    } catch (error) {
      console.error('Error marking asset as not verified:', error);
      alert('Failed to Mark Asset as Not Verified!');
    }
  };

  return (
    <Container maxWidth="xl" sx={{ mb: 4 }}>
      <Typography variant="h4" component="h1" sx={{ mt: 2 }} gutterBottom>
        Verify Asset
      </Typography>
      <form onSubmit={handleUpdateAsset}>
        <Grid container spacing={2}>
          {/* Form fields */}
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Code"
              variant="outlined"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Serial Number"
              variant="outlined"
              value={serialNumber}
              onChange={(e) => setSerialNumber(e.target.value)}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Vendor</InputLabel>
              <Select
                value={vendor}
                onChange={(e) => setVendor(e.target.value)}
                label="Vendor"
                disabled
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {vendors.map((vendor) => (
                  <MenuItem key={vendor.id} value={vendor.id}>{vendor.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Purchase Cost"
              variant="outlined"
              value={purchaseCost}
              onChange={(e) => setPurchaseCost(e.target.value)}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Old Location"
              variant="outlined"
              value={oldLocation}
              onChange={(e) => setOldLocation(e.target.value)}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="New Location"
              variant="outlined"
              value={newLocation}
              onChange={(e) => setNewLocation(e.target.value)}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Asset Type</InputLabel>
              <Select
                value={assetType}
                onChange={(e) => setAssetType(e.target.value)}
                label="Asset Type"
                disabled
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {assetTypes.map((assetType) => (
                  <MenuItem key={assetType.id} value={assetType.id}>{assetType.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Purchase Date"
                value={purchaseDate}
                onChange={(newValue) => setPurchaseDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                disabled
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Useful Life (Years)"
              variant="outlined"
              value={usefulLife}
              onChange={(e) => setUsefulLife(e.target.value)}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Custodian"
              variant="outlined"
              value={custodian}
              onChange={(e) => setCustodian(e.target.value)}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Condition"
              variant="outlined"
              value={condition}
              onChange={(e) => setCondition(e.target.value)}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Description"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Entity</InputLabel>
              <Select
                value={entity}
                onChange={(e) => setEntity(e.target.value)}
                label="Entity"
                disabled
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {entities.map((entity) => (
                  <MenuItem key={entity.id} value={entity.id}>{entity.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Branch</InputLabel>
              <Select
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
                label="Branch"
                disabled
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>{branch.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="primary" disabled type="submit">
            Save Changes
          </Button>
          <Button variant="contained" color="secondary" onClick={() => handleEdit(id)}>
            Edit
          </Button>
        </Box>
      </form>

      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Typography variant="h6">Verification Details</Typography>
          <Typography variant="body2">
            <strong>Verified By:</strong> {verifiedBy || 'N/A'}
          </Typography>
          <Typography variant="body2">
            <strong>Verification Date:</strong> {verificationDate ? verificationDate.format('DD-MM-YYYY') : 'N/A'}
          </Typography>
          <Typography variant="body2">
            <strong>Verification Status:</strong> {verificationStatus}
          </Typography>
        </CardContent>
      </Card>

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="contained" color="primary" onClick={handleVerifyAsset} disabled={isVerified}>
          Verify Asset
        </Button>
        <Button variant="contained" color="error" onClick={handleNotVerifyAsset} disabled={verificationStatus !== 'N/A' && verificationStatus !== 'Verified'}>
          Mark as Not Verified
        </Button>
      </Box>
    </Container>
  );
};

export default VerifyAsset;
