
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, InputAdornment } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink } from 'react-csv';

const AssetTypeList = () => {
  const [assetTypes, setAssetTypes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true; // Flag to track if the component is mounted

    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/assetTypes`)
      .then(response => {
        if (isMounted) {
          setAssetTypes(response.data);
        }
      })
      .catch(error => {
        if (isMounted) {
          console.error('Error fetching asset types:', error);
        }
      });

    return () => {
      isMounted = false; // Cleanup function to prevent memory leaks
    };
  }, []);

  const handleAddAssetType = () => {
    navigate('/dashboard/addAssetType');
  };

  const handleEdit = (assetTypeId) => {
    navigate(`/dashboard/editAssetType/${assetTypeId}`);
  };

  const handleDeleteAssetType = (assetTypeId) => {
    axios.delete(`${process.env.REACT_APP_BASE_URL}/auth/deleteAssetType/${assetTypeId}`)
      .then(() => {
        alert('Asset Type Deleted!');
        setAssetTypes((prevAssetTypes) => prevAssetTypes.filter(assetType => assetType.id !== assetTypeId));
      })
      .catch(error => console.error('Error deleting asset type:', error));
  };

  const filteredAssetTypes = assetTypes.filter((assetType) =>
    assetType.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'Name', key: 'name' },
  ];

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" sx={{ mt: 3, mb: 3 }} gutterBottom>
        Asset Types
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <TextField
          label="Search Asset Types"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddAssetType}
            style={{ marginRight: '16px' }}
          >
            Add Asset Type
          </Button>
          <CSVLink data={filteredAssetTypes} headers={headers} filename="asset_types.csv" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DownloadIcon />}
            >
              Download CSV
            </Button>
          </CSVLink>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAssetTypes.map((assetType) => (
              <TableRow key={assetType.id}>
                <TableCell>{assetType.id}</TableCell>
                <TableCell>{assetType.name}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEdit(assetType.id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDeleteAssetType(assetType.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AssetTypeList;
