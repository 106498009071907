
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MenuItem, FormControl, InputLabel, TextField, Button, Container, Typography, Grid, Select } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);

const Asset = () => {
  const [assets, setAssets] =  useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [vendor, setVendor] = useState('');
  const [vendors, setVendors] = useState([]);
  const [purchaseCost, setPurchaseCost] = useState('');
  const [oldLocation, setOldLocation] = useState('');
  const [newLocation, setNewLocation] = useState('');
  const [assetType, setAssetType] = useState('');
  const [assetTypes, setAssetTypes] = useState([]);
  const [purchaseDate, setPurchaseDate] = useState(dayjs());
  const [usefulLife, setUsefulLife] = useState('');
  const [custodian, setCustodian] = useState('');
  const [condition, setCondition] = useState('');
  const [description, setDescription] = useState('');
  const [entity, setEntity] = useState('');
  const [entities, setEntities] = useState([]);
  const [branch, setBranch] = useState('');
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    let isMounted = true; // Track if component is mounted

    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/vendors`)
      .then(response => {
        if (isMounted) setVendors(response.data);
      })
      .catch(error => console.error('Error fetching vendors:', error));

    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/assetTypes`)
      .then(response => {
        if (isMounted) setAssetTypes(response.data);
      })
      .catch(error => console.error('Error fetching asset types:', error));

    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/entities`)
      .then(response => {
        if (isMounted) setEntities(response.data);
      })
      .catch(error => console.error('Error fetching entities:', error));
    
    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/assets`)
      .then(response => {
        if (isMounted) setAssets(response.data);
      })
      .catch(error => console.error('Error fetching assets:', error));

    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/branches`)
      .then(response => {
        if (isMounted) setBranches(response.data);
      })
      .catch(error => console.error('Error fetching branches:', error));

    // Cleanup function to avoid memory leaks
    return () => {
      isMounted = false; // Mark component as unmounted
    };
  }, []);

  const handleAsset = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/addAsset`, {
        code, serialNumber, vendor, purchaseCost, oldLocation, newLocation, assetType, purchaseDate, usefulLife, custodian, condition, description, entity, branch
      });
      alert('Asset Added!');
      navigate('/dashboard/assets');
    } catch (error) {
      alert('Asset Not Added!');
    }
  };

  const handleEdit = (assetId) => {
    navigate(`/dashboard/editAssets/${assetId}`);
    console.log(assetId);
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" component="h1" sx={{ mt: 3, mb: 3 }} gutterBottom>
        Add Asset
      </Typography>
      <form onSubmit={handleAsset}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Code"
              variant="outlined"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Serial Number"
              variant="outlined"
              value={serialNumber}
              onChange={(e) => setSerialNumber(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Vendor</InputLabel>
              <Select
                value={vendor}
                onChange={(e) => setVendor(e.target.value)}
                label="Vendor"
                required
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {vendors.map((vendor) => (
                  <MenuItem key={vendor.id} value={vendor.id}>{vendor.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Purchase Cost"
              variant="outlined"
              value={purchaseCost}
              onChange={(e) => setPurchaseCost(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Location"
              variant="outlined"
              value={oldLocation}
              onChange={(e) => setOldLocation(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="New Location"
              variant="outlined"
              value={newLocation}
              onChange={(e) => setNewLocation(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Asset Type</InputLabel>
              <Select
                value={assetType}
                onChange={(e) => setAssetType(e.target.value)}
                label="Asset Type"
                required
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {assetTypes.map((assetType) => (
                  <MenuItem key={assetType.id} value={assetType.id}>{assetType.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Purchase Date"
                value={purchaseDate}
                onChange={(newValue) => setPurchaseDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" variant="outlined" required />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Useful Life"
              variant="outlined"
              value={usefulLife}
              onChange={(e) => setUsefulLife(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Custodian"
              variant="outlined"
              value={custodian}
              onChange={(e) => setCustodian(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Condition</InputLabel>
              <Select
                value={condition}
                onChange={(e) => setCondition(e.target.value)}
                label="Condition"
                required
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Good">Good</MenuItem>
                <MenuItem value="Fair">Fair</MenuItem>
                <MenuItem value="Poor">Poor</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              label="Description"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Entity</InputLabel>
              <Select
                value={entity}
                onChange={(e) => setEntity(e.target.value)}
                label="Entity"
                required
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {entities.map((entity) => (
                  <MenuItem key={entity.id} value={entity.id}>{entity.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Branch</InputLabel>
              <Select
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
                label="Branch"
                required
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>{branch.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ mt: 3, mb: 4 }}>
            <Button sx={{ mb: 4 }} type="submit" variant="contained" color="primary" fullWidth>
              Add Asset
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Asset;
