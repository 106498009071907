
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const VendorList = () => {
  const [vendors, setVendors] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchVendors = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/vendors`, {
          cancelToken: source.token,
        });
        setVendors(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error('Error fetching vendors:', error);
        }
      }
    };

    fetchVendors();

    return () => {
      source.cancel('Component unmounted: operation canceled');
    };
  }, []);

  const handleAddVendor = () => {
    navigate('/dashboard/addVendor');
  };

  const handleEditVendor = (vendorId) => {
    navigate(`/dashboard/editVendor/${vendorId}`);
  };

  const handleDeleteVendor = (vendorId) => {
    // Implement delete functionality here
    console.log('Delete Vendor', vendorId);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        Vendors
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddVendor}
        style={{ marginBottom: '16px', float: 'right' }}
      >
        Add Vendor
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Contract Terms</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vendors.map((vendor) => (
              <TableRow key={vendor.id}>
                <TableCell>{vendor.name}</TableCell>
                <TableCell>{vendor.phone}</TableCell>
                <TableCell>{vendor.address}</TableCell>
                <TableCell>{vendor.contractTerms}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEditVendor(vendor.id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDeleteVendor(vendor.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default VendorList;
