
// src/components/Dashboard.js
import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import Home from './Home';
import Register from './Register';
import { Box, CssBaseline, Typography } from '@mui/material';
import logo from '../assets/TAT.png'; // Import your logo

const Dashboard = () => {
  // State to manage sidebar visibility for mobile responsiveness
  const [showSidebar, setShowSidebar] = useState(false);

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />
      {/* Sidebar component with responsive visibility */}
      <Sidebar showSidebar={showSidebar} toggleSidebar={toggleSidebar} />
      
      {/* Main content area */}
      <Box component="main" sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {/* Topbar component with toggleSidebar passed for mobile responsiveness */}
        <Topbar toggleSidebar={toggleSidebar} />
        
        {/* Routes for different dashboard pages */}
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/register" element={<Register />} />
        </Routes>

        <Typography>Welcome</Typography>

        {/* Logo centered on the dashboard */}
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <img src={logo} alt="Logo" style={styles.logo} />
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  logo: {
    width: '200px', // Adjust the size of the logo as needed
    marginTop: '90px', // Optional: space between topbar and logo
  },
};

export default Dashboard;
