
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Container, Typography, Grid } from '@mui/material';

const EditEntity = () => {
  const { id } = useParams();  // Get the entity ID from the URL parameters
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    phone: '',
    address: ''
  });

  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    // Fetch the entity details by ID when the component mounts
    axios.get(`${process.env.REACT_APP_BASE_URL}/auth/entities/${id}`, { signal })
      .then(response => {
        const entity = response.data;
        setFormData({
          name: entity.name,
          description: entity.description,
          phone: entity.phone,
          address: entity.address
        });
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Fetch entity request canceled:', error.message);
        } else {
          console.error('Error fetching entity:', error);
        }
      });

    return () => {
      controller.abort(); // Cleanup: Cancel the request if the component unmounts
    };
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      const phonePattern = /^[0-9]{10}$/;
      if (!phonePattern.test(value)) {
        setPhoneError('Phone number must be 10 digits long.');
      } else {
        setPhoneError('');
      }
    }

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleUpdateEntity = async (e) => {
    e.preventDefault();

    if (phoneError) {
      alert('Please correct the errors before submitting the form.');
      return;
    }

    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/updateEntity/${id}`, formData);
      alert('Entity Updated!');
      navigate('/dashboard/entities');
    } catch (error) {
      alert('Entity Not Updated!');
      console.error('Error updating entity:', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" sx={{ mt: 2 }} gutterBottom>
        Edit Entity
      </Typography>
      <form onSubmit={handleUpdateEntity}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Entity Name"
              variant="outlined"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Description"
              variant="outlined"
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Phone"
              variant="outlined"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              error={phoneError !== ''}
              helperText={phoneError}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Address"
              variant="outlined"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Update Entity
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default EditEntity;
